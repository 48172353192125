import { FULLSOCIALMEDIALIST } from '@/constants/social-media';
import React from 'react';
import { SocialMediaButton } from '../social-media-button';
import "./social-media-block.scss";

const SocialMediaBlock: React.FC = () => {
	return (
		<nav className="social-media-block" aria-labelledby="social media navigation">
      <h3 className="atmospheric-text">Follow our socials!</h3>
			{Object.keys(FULLSOCIALMEDIALIST).map((item: string) => {
				return (
					<div key={item}>
            <SocialMediaButton
              socialMedia={item as SocialMediaName}
              url={FULLSOCIALMEDIALIST[item as SocialMediaName] || ''}
            />
          </div>
				);
			})}
		</nav>
	);
};

export { SocialMediaBlock };
