import clsx from 'clsx';
import React from 'react';
import './form-field.scss';

export interface FormFieldProps {
	label?: string;
	placeholder?: string;
	id: string;
	type?: 'text' | 'textarea' | 'date' | 'tel' | 'email';
  className?: string;
	name: string;
	required?: boolean;
	pattern?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormField: React.FC<FormFieldProps> = ({
	label,
	placeholder,
	id,
	type = 'text',
  className,
	name,
	required,
	pattern,
	onChange
}: FormFieldProps) => {
	return (
		<div className={clsx("form-field", className)}>
			{label && <label htmlFor={id} className="corbel-text">{label}</label>}
			{type === 'textarea' ? (
				<textarea id={id} rows={10} name={name} required={required} placeholder={placeholder} />
			) : (
				<input id={id} type={type} name={name} required={required} placeholder={placeholder} pattern={pattern} onChange={onChange} />
			)}
		</div>
	);
};

export { FormField };
