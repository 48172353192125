import React from 'react';
import './wallet-info.scss';
import { configs } from '@/constants/configs';

const WalletInfo: React.FC = () => {

	return (
		<div
			className="wallet-info"
		>
			<h3 className="atmospheric-text">Official Wallet Address</h3>
			<p>
        <a className="corbel-text" href={`${configs.walletAddressBaseUrl}${configs.walletKey}`} target="blank">
          {configs.walletKey}
        </a>
      </p>
		</div>
	);
};

export { WalletInfo };
