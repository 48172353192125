import React from 'react';
import { SocialMediaBanner } from '@/components/social-media-banner';
import clsx from 'clsx';
import { ContactPageFooter } from '@/components/contact-page-footer';
import { configs } from '@/constants/configs';
import { RootLayout } from '../root-layout';

export interface ContactLayoutProps {
	children: React.ReactNode;
	className?: string;
	pageTitle?: string;
	pageDescription?: string;
}

const ContactLayout: React.FC<ContactLayoutProps> = ({
	children,
	className,
	pageTitle = configs.defaultPageTitle,
	pageDescription = configs.defaultPageDescription,
}: ContactLayoutProps) => {
	return (
		<RootLayout pageTitle={pageTitle} pageDescription={pageDescription}>
			<main className={clsx(className)}>
				<SocialMediaBanner />
				{children}
			</main>
			<ContactPageFooter />
		</RootLayout>
	);
};

export { ContactLayout };
