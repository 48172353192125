import React from 'react';
import { Button } from '../button';
import { FormField } from '../form-field';
import './contact-form.scss';
import { useGlobalSetting } from '@/contexts/GlobalSettingContext';
import {
	APP_CONTACTFORM_API,
	APP_CONTACTFORM_REDIRECTURL,
} from 'gatsby-env-variables';

const ContactForm: React.FC = () => {
	const { toggleGlobalLoader, toggleModal } = useGlobalSetting();
	const fileFieldRef = React.useRef<HTMLInputElement>(null);
	const [date, setDate] = React.useState<Date>();

	const onFileUpload = () => {
		fileFieldRef.current?.click();
	};

	const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newDate: Date = new Date(event.target.value);
		setDate(newDate);
	};

	React.useEffect(() => {
		const params = new URLSearchParams(location.search);
		if (params.get('isSubmitted') === 'true') {
			toggleModal(() => ({
				toggle: true,
				className: 'alert-dialog',
				body: <>
					<h2 className="atmospheric-text">Thank you!</h2>
					<p>We have received your contact and we will get in touch with you very soon!</p>
				</>,
			}));
		}
	}, []);

	return (
		<form
			className="contact-form"
			method="post"
			acceptCharset="UTF-8"
			action={APP_CONTACTFORM_API}
		>
			<h3 className="atmospheric-text">General Enquiry</h3>
			<p className="corbel-text">
				Do you want to find out more about our project or have an idea
				of a possible collaboration? Please fill in the form and we will
				get back to you as soon as possible.
			</p>
			<input type="hidden" name="meta_web_form_id" value="1401247103" />
			<input type="hidden" name="meta_split_id" value="" />
			<input type="hidden" name="listname" value="awlist6289208" />
			<input
				type="hidden"
				name="redirect"
				value={APP_CONTACTFORM_REDIRECTURL}
				id="redirect_a9d0012bd72788fad7df07332f68926e"
			/>

			<input type="hidden" name="meta_adtracking" value="Contact_form" />
			<input type="hidden" name="meta_message" value="1" />
			<input
				type="hidden"
				name="meta_required"
				value="name,email,custom Message"
			/>
			<input type="hidden" name="meta_forward_vars" value="1" />
			<div className="contact-form__body">
				<FormField
					placeholder="Name"
					type="text"
					id="awf_field-114428337"
					className="half-width"
					name="name"
					required
				/>
				<FormField
					placeholder="Email"
					type="email"
					id="awf_field-114428340"
					name="email"
					className="half-width"
					required
				/>
				<FormField
					placeholder="Message"
					type="textarea"
					id="awf_field-114428342"
					name="custom Message"
					className="full-width"
					required
				/>
				{/* <input
					className="hidden"
					type="file"
					name="file"
					placeholder="Attachments (optional)"
					accept="image/*,.pdf,.doc"
					ref={fileFieldRef}
				/> */}
			</div>
			<div className="contact-form__footer">
				{/* <Button theme="secondary" onClick={onFileUpload}>
					Attach file
				</Button> */}
				<button type="submit">Send</button>
			</div>
		</form>
	);
};

export { ContactForm };
