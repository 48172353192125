import '../styles/global.scss';
import '../styles/contact-page.scss';
import { ContactForm } from '@/components/contact-form';
import { ContactLayout } from '@/layouts/contact-layout';
import { configs } from '@/constants/configs';
import { WalletInfo } from '@/components/wallet-info/WalletInfo';

const pageName: string = 'Contact Us';

export default function ContactUs() {
  return (
    <ContactLayout className="contact-page" pageTitle={`${configs.defaultPageTitle} - ${pageName}`}>
      <div className="contact-page__header-wrapper" data-content={pageName}>
        <h2 className="contact-page__header atmospheric-text">
          {pageName}
        </h2>
        <WalletInfo />
      </div>
      <ContactForm />
    </ContactLayout>
  );
}
