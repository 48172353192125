import React from 'react';
import { LazyLoadBackgroundImage, LazyLoadBackgroundImageProps } from '@/components/lazy-load-background-image';
import { graphql, StaticQueryDocument, useStaticQuery } from 'gatsby';
import clsx from 'clsx';
import './hexagon-bg-layout.scss';

export interface HexagonBGLayoutProps extends Pick<LazyLoadBackgroundImageProps, 'Tag'> {
	children: React.ReactNode;
	className?: string;
}

const query: StaticQueryDocument = graphql`
	query {
		bgImage: file(relativePath: { eq: "images/hexagon_bg.png" }) {
			childImageSharp {
				gatsbyImageData(
          quality: 90
          placeholder: BLURRED
        )
			}
		}
	}
`;

const HexagonBGLayout: React.FC<HexagonBGLayoutProps> = ({
	children,
  Tag,
  className
}: HexagonBGLayoutProps) => {
	const { bgImage } = useStaticQuery(query);
	return (
		<LazyLoadBackgroundImage Tag={Tag} imageGraphql={bgImage} className={clsx('hexagon-bg-layout', className)}>
			{children}
		</LazyLoadBackgroundImage>
	);
};

export { HexagonBGLayout };
