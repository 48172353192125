import React from 'react';
import MapImage from '@images/contact/map.png';
import './contact-page-footer.scss';
import { SocialMediaBlock } from '../social-media-block';
import { ContactDetails, ContactInfo } from '../contact-details';
import PhoneIcon from '@images/contact/phone.svg';
import EmailIcon from '@images/contact/email.svg';
import PinIcon from '@images/contact/pin.svg';
import { HexagonBGLayout } from '@/layouts/hexagon-bg-layout';

const details: ContactInfo[] = [
  {
    icon: PhoneIcon,
    info: '+65 xxxx xxxx'
  },
  {
    icon: EmailIcon,
    info: 'info@playgroundonline.io'
  },
  {
    icon: PinIcon,
    info: 'Location Address xxx, something street, SG xxxxxx'
  }
];

const ContactPageFooter: React.FC = () => {
  return <footer className="contact-page-footer">
    <HexagonBGLayout Tag="div" className="contact-page-footer__subcontainer">
      <SocialMediaBlock />
    </HexagonBGLayout>
  </footer>
};

export { ContactPageFooter };
